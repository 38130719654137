<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <section class="pt-0">
    <h3 class="font-weight-bolder mb-2 d-flex align-items-center justify-content-between">
      Your Details
      <b-button @click="switchEdit(!edit)">{{edit ? 'Cancel Update' : 'Edit'}}</b-button>
    </h3>

    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateProfile)" :class="{'view-only': !edit}">
        <section class="mb-2">
          <label class="font-weight-bolder">Name</label>
          <validation-provider
            v-slot="validationContext"
            name="Name"
            :rules="{ required: true }"
          >
            <b-form-input
              v-model="profileEdit.name"
              :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter name"
            />

            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </section>

        <section class="mb-2">
          <label class="font-weight-bolder">Email</label>
          <validation-provider
            v-slot="validationContext"
            name="Email"
            type="email"
            rules="required|email"
          >
            <b-form-input
              v-model="profileEdit.email"
              :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter email"
            />

            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </section>

        <section class="d-flex full-width justify-content-end">
          <b-button variant="primary" v-if="edit" type="submit">Save details</b-button>
        </section>
      </b-form>
    </validation-observer>
  </section>
</template>
<!--eslint-enable-->

<script>
import ProfileService from '@/services/ProfileService';

export default {
  name: 'ProfileDetails',
  components: {},
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      edit: false,
      profileEdit: {
        name: '',
        email: '',
      },
    };
  },
  mounted() {
   this.setProfileEditToDefault();
  },
  methods: {
    updateProfile() {
      this.loading = true;
      ProfileService.updateProfile(this.profileEdit).then(() => {
        this.edit = false;
        this.$toast.success('Updated profile successfully', {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.$emit('refresh');
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not update profile, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }).finally(() => {
        this.loading = false;
      });
    },
    setProfileEditToDefault() {
      this.profileEdit = { ...this.profile };
    },
    switchEdit(value) {
      this.edit = value;
      this.setProfileEditToDefault();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
