<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <section class="pt-0">
    <h3 class="font-weight-bolder mb-2">
      Change Password
    </h3>

    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateProfilePassword)">
        <section class="mb-2">
          <label class="font-weight-bolder">Old Password</label>
          <validation-provider
            v-slot="validationContext"
            name="Old Password"
            :rules="{ required: true }"
          >
            <b-input-group class="mb-1">
              <b-form-input v-model="password.old_password" :state="getValidationState(validationContext)"
                            :type="showPassword ? 'text' : 'password'" placeholder="Enter old password*" />
              <b-input-group-append>
                <b-button @click="showPassword = !showPassword">
                  <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" />
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </section>

        <section class="mb-2">
          <label class="font-weight-bolder">New Password</label>
          <validation-provider
            v-slot="validationContext"
            name="New Password"
            :rules="rules"
          >
            <b-input-group class="mb-1">
              <b-form-input v-model="password.new_password" :state="getValidationState(validationContext)"
                            :type="showPassword2 ? 'text' : 'password'" placeholder="Enter new password*" />
              <b-input-group-append>
                <b-button @click="showPassword2 = !showPassword2">
                  <i class="fas" :class="showPassword2 ? 'fa-eye' : 'fa-eye-slash'" />
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </section>

        <section class="mb-2">
          <label class="font-weight-bolder">Confirm New Password</label>
          <validation-provider
            v-slot="validationContext"
            name="New Password Confirm"
            :rules="rules"
          >
            <b-input-group class="mb-1">
              <b-form-input v-model="password.new_password_confirm" :state="getValidationState(validationContext)"
                            :type="showPassword3 ? 'text' : 'password'" placeholder="Re-enter new password*" />
              <b-input-group-append>
                <b-button @click="showPassword3 = !showPassword3">
                  <i class="fas" :class="showPassword3 ? 'fa-eye' : 'fa-eye-slash'" />
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </section>

        <section class="d-flex full-width justify-content-end">
          <b-button variant="primary" type="submit">Change Password</b-button>
        </section>
      </b-form>
    </validation-observer>
  </section>
</template>
<!--eslint-enable-->

<script>
import ProfileService from '@/services/ProfileService';

export default {
  name: 'ProfileSecurity',
  components: {},
  props: {
    strongPasswordRequired: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
      password: {
        old_password: '',
        new_password: '',
        new_password_confirm: '',
      },
      rules: {required: true, min: 12,},
      info_text: "",
    };
  },
  mounted() {
    this.setRules(this.strongPasswordRequired)
  },
  methods: {
    updateProfilePassword() {
      this.loading = true;
      ProfileService.changeProfilePassword(this.password.old_password, this.password.new_password).then(() => {
        this.$toast.success('Updated password successfully', {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.$emit('refresh');
      }).catch(err => {
        this.$toast.error(this.getErrorMessage(err), { toastClassName: ['toast-std', 'warning-toast'] });
      }).finally(() => {
        this.loading = false;
      });
    },
    getErrorMessage(err) {
      let errorMsg = 'Could not update profile, please refresh and try again';
      const errorFound = err.response && err.response.data && err.response.data.error;
      if (errorFound) {
        const { error } = err.response.data;
        if (error === 'Incorrect password') {
          errorMsg = 'Your old password (current) is incorrect, please check and try again';
        }
      }

      return errorMsg;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    setRules(strongPasswordRequired) {
      const strongPassText = strongPasswordRequired ? " Character patterns and repeated characters are not allowed." : "";
      this.rules = {required: true, min: 12, strongPasswordSequence: strongPasswordRequired, strongPasswordPattern: strongPasswordRequired}
      this.info_text = "Required length `12`."+strongPassText+" Complexity rules can be configured on your Enterprise page."
    },
  },
};
</script>
